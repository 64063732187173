import consumer from "./consumer"

jQuery(document).ready(function(){
  consumer.subscriptions.create("PayrollSheetAlertsChannel", {
    received: (data) => {
      alert = `<div class="alert alert alert-dismissible alert-${data.success ? 'success' : 'danger'} text-center" role="alert">` +
                '<button class="close" data-dismiss="alert" type="button">' +
                  '<span aria-hidden="true">×</span><span class="sr-only">Close</span>' +
                '</button>' +
                `${data.message}` +
              '</div>';

      $('.alert-container').html(alert);
      $(".alert-container").fadeTo(5000, 1000).slideUp(1000, function() {
        $(".alert-container").slideUp(1000);
      });
    }
  });
});
